import ElectroIcon from "../icons/ElectroIcon";
import TruckIcon from "../icons/truckIcon";
import WarehouseIcon from "../icons/WarehouseIcon";
import "../styles/About.scss";

const About = () => {
  return (
    <div className="About">
      <div className="AboutBody">
        <h1>Про нас.</h1>
        <div className="AboutLine"></div>
        <p>Наша компанія налічує різноманітні товари для збереження вашого світла у будь-який час.</p>
        <div className="AboutAdvantages">
          <div className="AboutAdvantagesItem">
            <TruckIcon />
            <div className="AboutAdvantagesText">
              <h4>Логістика.</h4>
              <p>Оперативно доставляємо обладнання в різні частини України!</p>
            </div>
          </div>
          <div className="AboutAdvantagesItem">
            <WarehouseIcon />
            <div className="AboutAdvantagesText">
              <h4>Склади.</h4>
              <p>Всі товари в наявності та готові до відправки до наших клієнтів!</p>
            </div>
          </div>
          <div className="AboutAdvantagesItem">
            <ElectroIcon />
            <div className="AboutAdvantagesText">
              <h4>Енергетика.</h4>
              <p>Всі наші клієнти забули про відключення світла і продовжують працювати у штатному режимі!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
