const baseUrl = "https://api.telegram.org/bot7248140585:AAH9hQcUwje5VTYoZDkWEpEERcayyeuSbyM/";

export const sendMessage = async (message: string): Promise<void> => {
  const url: string = `${baseUrl}sendMessage?chat_id=-4588349963&text=${message}`;

  console.log(message);
  const response: Response = await fetch(url);

  console.log("response", response);
};
