import { useEffect, useState } from "react";
import "./App.scss";
import About from "./components/About";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Main from "./components/Main";
import Modal from "./components/Modal";
import Products from "./components/Products";

function App() {
  const [isModal, setIsModal] = useState(false);
  const [isLoad, setIsLoad] = useState(true);
  const [isLoadBody, setIsLoadBody] = useState(true);
  let type = "Настінні батареї";

  useEffect(() => {
    setTimeout(() => {
      setIsLoad(false);
    }, 4000);
  }, []);
  useEffect(() => {
    setTimeout(() => {
      setIsLoadBody(false);
    }, 100);
  }, []);

  const openModal = () => {
    setIsModal(!isModal);
  };

  return (
    <div className="App">
      {isLoad === true ? (
        <div className="loaderBody">
          <div className="container">
            <div className="loader">
              <div className="crystal"></div>
              <div className="crystal"></div>
              <div className="crystal"></div>
              <div className="crystal"></div>
              <div className="crystal"></div>
              <div className="crystal"></div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {isLoadBody === true ? (
        <></>
      ) : (
        <>
          {isModal && <Modal openModal={openModal} typeItem={type}></Modal>}
          <Header></Header>
          <Main openModal={openModal}></Main>
          <About></About>
          <Products></Products>
          <Footer></Footer>
        </>
      )}
    </div>
  );
}

export default App;
