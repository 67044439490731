import { useRef, useState } from "react";
import "../styles/Header.scss";
import Modal from "./Modal";

const Header = () => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isBurger, setIsBurger] = useState(false);
  const [isModal, setIsModal] = useState(false);

  const openModal = () => {
    setIsModal(!isModal);
  };

  const setMenu = () => {
    if (menuRef.current) {
      if (isBurger) {
        menuRef.current.classList.remove("menu_opened");
        menuRef.current.classList.add("menu_closed");
      } else {
        menuRef.current.classList.remove("menu_closed");
        menuRef.current.classList.add("menu_opened");
      }
    }

    setIsBurger(!isBurger);
  };

  const scrollToMain = () => {
    const aboutElement = document.getElementById("main") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToProducts = () => {
    const aboutElement = document.getElementById("products") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToFooter = () => {
    const aboutElement = document.getElementById("contacts") as HTMLElement;
    aboutElement.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div className="Header">
      {isModal && <Modal openModal={openModal} typeItem={"Настінні батареї"}></Modal>}
      <div className="Header_body">
        <div className="Header_logo">
          <img src={process.env.PUBLIC_URL + "evolutionLogo.png"} alt="" />
          <h1>Energy Evolution</h1>
        </div>
        <div className="Header_nav">
          <button className="Header_nav_button" onClick={scrollToMain}>
            Головна
          </button>
          <button className="Header_nav_button" onClick={scrollToProducts}>
            Товари
          </button>
          <button className="Header_nav_button" onClick={scrollToFooter}>
            Контакти
          </button>
        </div>
        <div className="Header_btn">
          <button className="cssbuttons-io-button" onClick={openModal}>
            Замовити
            <div className="icon">
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </button>
        </div>
        <input id="burger-checkbox" type="checkbox" onClick={setMenu} />
        <label className="burger" htmlFor="burger-checkbox">
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>

      <div className="Header__burger_nav menu_closed" ref={menuRef}>
        <div className="burger_nav_body">
          <div className="Header_nav_burger">
            <button
              className="Header_nav_burger_button"
              onClick={() => {
                scrollToMain();
                const check = document.getElementById("burger-checkbox") as HTMLInputElement;
                check.checked = false;
                setMenu();
              }}
            >
              Головна
            </button>
            <button
              className="Header_nav_burger_button"
              onClick={() => {
                scrollToProducts();
                const check = document.getElementById("burger-checkbox") as HTMLInputElement;
                check.checked = false;
                setMenu();
              }}
            >
              Товари
            </button>
            <button
              className="Header_nav_burger_button"
              onClick={() => {
                scrollToFooter();
                const check = document.getElementById("burger-checkbox") as HTMLInputElement;
                check.checked = false;
                setMenu();
              }}
            >
              Контакти
            </button>
          </div>
          <div className="Header_burger_btn" onClick={openModal}>
            <button className="cssbuttons-io-button">
              Замовити
              <div className="icon">
                <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path
                    d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                    fill="currentColor"
                  ></path>
                </svg>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
