import "../styles/Main.scss";

const Main = ({ openModal }: any) => {
  return (
    <div className="Main" id="main">
      <div className="Main_body">
        <div className="Main_content">
          <div className="Main__logos">
            <img src={process.env.PUBLIC_URL + "deye.png"} alt="deye_logo" />
            <img src={process.env.PUBLIC_URL + "felicity.png"} alt="felicity_logo" />
          </div>
          <h1>Купуй Енергію.</h1>
          <h4>Акумулятори · Інвертори · Сонячні Батареї </h4>
          <button className="cssbuttons-io-button" onClick={openModal}>
            Замовити
            <div className="icon">
              <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </button>
        </div>
        <div className="Main_images">
          <img src={process.env.PUBLIC_URL + "eeMain1.png"} alt="" className="eeMain1" />
          <img src={process.env.PUBLIC_URL + "eeMain2.png"} alt="" className="eeMain2" />
          <img src={process.env.PUBLIC_URL + "eeMain3.png"} alt="" className="eeMain3" />
        </div>
      </div>
    </div>
  );
};

export default Main;
