import { useEffect, useState } from "react";
import "../styles/Modal.scss";
import { message } from "antd";
import { useMask } from "@react-input/mask";
import { sendMessage } from "../api/telegram";
import { getFormSubmissionCount, incrementFormSubmissionCount } from "../api/localStorageHelper";

const messages = [
  {
    messageSuccess: "Ваша заявка успішно надіслана!",
  },
  {
    messageError: "Заповніть усі поля!",
  },
  {
    messageLimit: "Ви досягли максимальної кількості заявок.",
  },
];

const Modal = ({ openModal, typeItem }: any) => {
  const inputRef = useMask({ mask: "+38(___)-___-__-__", replacement: { _: /\d/ } });
  const [messageApi, contextHolder] = message.useMessage();

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    text: "",
    type: `${typeItem}`,
  });

  const messageError = () => {
    messageApi.open({
      type: "error",
      content: messages[1].messageError,
      className: "Message",
    });
  };

  const messageLimit = () => {
    messageApi.open({
      type: "warning",
      content: messages[2].messageLimit,
      className: "Message",
    });
  };

  const handleChange = (e: any) => {
    const { name, value, files, type } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
    } else if (type === "radio") {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const submissionCount = getFormSubmissionCount();

    if (submissionCount >= 3) {
      messageLimit();
      return;
    }

    try {
      if (formValues.name && formValues.phone && formValues.text) {
        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone} %0A- Коментар: ${formValues.text} %0A- Категорія яка цікавить клієнта: ${formValues.type}`;
        console.log(message);
        await sendMessage(message);
        openModal();
        // messageSuccess();
        incrementFormSubmissionCount();
      } else {
        messageError();
      }
    } catch (e) {
      console.log("Error");
    } finally {
    }
  };

  return (
    <div className="Modal">
      {contextHolder}
      <div className="ModalOverlay" onClick={openModal}></div>
      <div className="ModalBody">
        <div className="card">
          <span className="title">Залиште замовлення</span>
          <form className="form">
            <div className="group">
              <input
                placeholder="‎"
                id="name"
                type="text"
                name="name"
                className="input"
                value={formValues.name}
                onChange={handleChange}
              />
              <label htmlFor="name">Ім'я</label>
            </div>
            <div className="group">
              <input
                placeholder="‎"
                id="phone"
                type="phone"
                name="phone"
                className="input"
                ref={inputRef}
                value={formValues.phone}
                onChange={handleChange}
              />
              <label htmlFor="phone">Телефон</label>
            </div>
            <div className="group">
              <input
                placeholder="‎"
                id="message"
                type="text"
                name="text"
                className="input"
                value={formValues.text}
                onChange={handleChange}
              />
              <label htmlFor="message">Повідомлення</label>
            </div>
            <div className="radio-input">
              <label className="label">
                <input
                  type="radio"
                  id="Настінні батареї"
                  checked={formValues.type === "Настінні батареї"}
                  name="type"
                  value="Настінні батареї"
                  onChange={handleChange}
                />
                <p className="text">Настінні</p>
              </label>
              <label className="label">
                <input
                  type="radio"
                  id="Батареї на колесиках"
                  checked={formValues.type === "Батареї на колесиках"}
                  name="type"
                  value="Батареї на колесиках"
                  onChange={handleChange}
                />
                <p className="text">На колесиках</p>
              </label>
              <label className="label">
                <input
                  type="radio"
                  id="Наборні Батареї"
                  checked={formValues.type === "Наборні Батареї"}
                  name="type"
                  value="Наборні Батареї"
                  onChange={handleChange}
                />
                <p className="text">Наборні</p>
              </label>
              <label className="label">
                <input
                  type="radio"
                  id="Інвертори"
                  checked={formValues.type === "Інвертори"}
                  name="type"
                  value="Інвертори"
                  onChange={handleChange}
                />
                <p className="text">Інвертори</p>
              </label>
              <label className="label">
                <input
                  type="radio"
                  id="Сонячні панелі"
                  checked={formValues.type === "Сонячні панелі"}
                  name="type"
                  value="Сонячні панелі"
                  onChange={handleChange}
                />
                <p className="text">Сонячні панелі</p>
              </label>
              <label className="label">
                <input
                  type="radio"
                  id="Комплексне рішення"
                  checked={formValues.type === "Комплексне рішення"}
                  name="type"
                  value="Комплексне рішення"
                  onChange={handleChange}
                />
                <p className="text">Комплексне рішення</p>
              </label>
            </div>
            <button type="submit" onClick={handleSubmit}>
              Відправити
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Modal;
